<template>
  <dv-full-screen-container>
<el-container class="home_container">
  <!--侧边-->
      <el-aside :width="isCollspase?'64px':'220px'">
        <div class="logo_img">
          <img :src="imgUrl"/>
        </div>
        <el-menu unique-opened background-color="#304156" text-color="white" :default-active="this.$router.history.current.path"  class="el-menu-vertical-demo" :collapse='isCollspase' :collapse-transition='false' router>
              <el-menu-item :index="'/homepage'" key="1">
                <i class="el-icon-s-home"></i>
                <span slot="title">主页</span>
              </el-menu-item>

              <el-submenu :index="item.id" v-for="item in listMenu" :key="item.id">
                <template slot="title">
                  <i :class="iconArray[item.id]"></i>
                  <span> {{item.name}}</span>
                </template>

                <el-menu-item :index="'/'+subitem.path"  v-for="subitem in item.chilidrens" :key="subitem.id" style="margin-left: 15px;">
                   <template slot="title">
                    <span> {{subitem.name}}</span>
                  </template>
                </el-menu-item>
              </el-submenu>
        </el-menu>
      </el-aside>

  <el-container>
      <!--头部-->
      <el-header>
        <el-button type="primary" :icon='isCollspase?"el-icon-s-unfold":"el-icon-s-fold"' style="float:left;background-color:#919497;border-color:#919497;" @click="toggleCollspace"></el-button>
        <div class="remind">

          <!--提醒-->
          <!-- <div style="margin-right:50px">

            <div class="remind" style="cursor:pointer" @click="Remind">
              <i class="el-icon-bell"/>
              <label style="color:red;margin-bottom:15px">●</label>
            </div>
          </div> -->

          <div v-if="User.user_type===2||User.user_type===4" style="margin-right:50px">
            <div class="remind">
              <img src="../../assets/image/daping.png" style="width: 25px;height: 25px;"/>
              <router-link style="color:#0E5FF6;font-size: 14px;margin-left: 5px;cursor:pointer;text-decoration:none" target="_blank" :to="{name:'datascreen',params:{token:tokenStr}}">数据大屏</router-link>
            </div>
          </div>

          <el-dropdown size='medium' @command='loginOut'>
            <span class="el-dropdown-link">
              <i class="el-icon-user el-icon-arrow-down"></i>
              {{login_name}}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-key" command="a">修改密码</el-dropdown-item>
              <el-dropdown-item icon="el-icon-switch-button" command="b">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

      </el-header>

      <!--主体-->
      <el-main>
        <router-view></router-view>
      </el-main>
  </el-container>

  <el-dialog v-if="dialogVisible" title="修改密码" :width="isCollspase?'50%':'30%'" :visible.sync="dialogVisible" >
    <PwdForm ref='childRules' :pwdForm='pwdForm'/>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="EditPwd">确 定</el-button>
    </span>
  </el-dialog>
</el-container>
</dv-full-screen-container>
</template>

<script>
import util from '../../util/util'
import PwdForm from '../../components/pwd.vue'


export default {
  data () {
    return {
      imgUrl:require("../../assets/image/logo.png"),
      screenWidth: 0,
      dialogVisible: false,
      defaultOpeneds: ['/homepage'],
      login_name: '',
      User:null,
      tokenStr:'',

      isCollspase: false,
      iconArray: {
        1: 'el-icon-house',
        2: 'iconfont icon-tingchechang',
        3: 'iconfont icon-car',
        4: 'el-icon-s-shop',
        5: 'iconfont icon-fl-baobiao',
        6: 'el-icon-setting',
        7: 'el-icon-menu',
        8: 'el-icon-s-grid',
        9: 'el-icon-pie-chart',
        10: 'iconfont icon-guzhangtousu',
        11: 'el-icon-s-shop',
        12: 'iconfont icon-fl-baobiao',
        13: 'iconfont icon-erweima'
      },
      pwdForm: {
        user_id: 0,
        user_pwd: ''
      }
    }
  },
  created () {
    this.screenWidth = document.body.clientWidth
    if (this.screenWidth > 980) { this.isCollspase = false } else { this.isCollspase = true }
    this.listMenu = JSON.parse(window.sessionStorage.getItem('MeunItem'))
    this.tokenStr=util.GetToken()
    this.User = util.JesToken()
     if(this.User.logo_name){
       this.imgUrl=util.GetImageUrl(this.User.logo_name)
     }
  },
  mounted () {
    this.login_name = this.User.user_name
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
        if (this.screenWidth > 980) { this.isCollspase = false } else { this.isCollspase = true }
      })()
    }
    window.addEventListener('unload',e=>this.unLoad(e))
  },
  components: {
    PwdForm
  },
  methods: {
    unLoad(){
      util.Get('login/loginout')
    },

    toggleCollspace () {
      this.isCollspase = !this.isCollspase
    },
    loginOut (command) {
      if (command === 'a') {
        this.dialogVisible = true
      }
      if (command === 'b') {
        util.Get('login/loginout')
        window.sessionStorage.removeItem('ca_login_token')
        this.$router.push('/')
       
      }
    },
    EditPwd () {
      const flag = this.$refs.childRules.validateForm()
      if (flag) {
        this.pwdForm.user_id=this.User.user_id
        util.Post('user/editpwd', this.pwdForm).then(res => {
          if (res.rpStatus === 10000) {
            this.$message({
              message: '修改密码成功',
              type: 'success'
            })
            this.dialogVisible = false
          }
        })
      }
    },
    Remind () {

    },
    destroyed(){
      window.removeEventListener('unload',e=>this.unLoad(e))
    }
  }
}
</script>

<style lang="scss" scoped>
.home_container{
  height: 100%;
}

.el-menu-item{
  padding: 0 40px;
}

._pwd_form{
  display: flex;
  flex-direction: column;
  justify-content: start;
}
._pwd_form div{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
._pwd_form div>label{
  width: 100px;
}

.dropdown_btn{
float: right;
}

.logo_img{
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo_img img{
    max-height: 60px;
    width: 100%;
}



.remind{
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.el-header {
    background-color: white;
    color: #333;
    line-height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .el-aside {
    background-color: #304156;
    color: #333;
    text-align: center;
    line-height: 230px;
    .el-menu{
      border-right: none;
    }
  }




  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;

  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

.el-dropdown{
  float: right;
}

.el-dropdown-link {
    cursor: pointer;
}
.el-icon-arrow-down {
    font-size: 20px;
}
/* 
::v-deep .el-dialog__wrapper { 
  .el-dialog {
    .el-dialog__header{
      padding: 10px 20px 10px;
      background-color: #319FE4;
    }
    .el-dialog__headerbtn {
      top: 5px;
      right: 5px;
      padding-top: 10px;
    }
    .el-dialog__title,.el-dialog__headerbtn i{
      color: white;
    }
    .el-dialog__headerbtn .el-dialog__close {
      height: 30px;
      width: 35px;
    }
    .el-tab-pane{
        display:inline
    }
  }
  .el-dialog__body{
    .el-card__header{
      padding: 5px 10px;
      border-bottom:none;
      text-align: left;
    }
  }
} */


</style>
